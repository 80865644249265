<template>
  <div>
    <iframe
      id="iframe-team"
      ref="myElement"
      frameborder="1"
      :height="`${height}px`"
      width="100%"
      scrolling="no"
      :src="`${VUE_APP_BASE_AUTH_MODULE}/team`"
      allow="clipboard-read; clipboard-write"
    />
    <ModalResetPassword :team-id="teamId" />
  </div>

</template>

<script>
import ModalResetPassword from './ModalResetPassword.vue'

export default {
  components: { ModalResetPassword },
  data() {
    return {
      height: 0,
      VUE_APP_BASE_AUTH_MODULE: process.env.VUE_APP_BASE_AUTH_MODULE,
      teamId: null,
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === this.VUE_APP_BASE_AUTH_MODULE) {
        const {
          type, data, route, team_id,
        } = event.data

        if (type === 'SET_DIMENSION') {
          this.height = Math.floor(data.height)
        }

        if (type === 'RESET_PASSWORD') {
          this.teamId = team_id
          this.$bvModal.show('change-password-team')
        }

        if (route) {
          this.$router.push(route)
        }
      }
    })
  },
}
</script>
