<template>
  <b-col
    lg="4"
    md="6"
    cols="12"
  >
    <b-card no-body>
      <b-overlay
        :show="loadingCurrentSaldo"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0.3"
      >
        <b-card-body class="h-[12rem] md:h-[12rem] pb-0">
          <div class="d-flex mb-1 justify-content-between">
            <div class="d-flex text-2xl font-semibold align-self-center">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg"
                class="align-self-center"
              >
              <span class="align-self-center mx-[6px]">Saldo KomPay</span>
              <img
                id="balanceTooltip"
                class="align-self-center"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              >
              <b-popover
                triggers="hover"
                target="balanceTooltip"
                placement="bottomleft"
              >Saldo kamu saat ini.</b-popover>
            </div>
            <div
              v-if="profile.role_id === 4"
              class="flex align-self-center"
            >
              <img
                id="detailSaldo"
                type="button"
                class="border border-light p-[5px] hover:bg-[#E2E2E2] rounded-lg"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/document-text.svg"
                alt="setting"
                @click="$router.push('/keuangan/saldo/detail')"
              >
              <img
                id="paymentSettings"
                type="button"
                class="border border-light p-[5px] hover:bg-[#E2E2E2] rounded-lg"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/setting-2.svg"
                alt="setting"
                @click="$router.push('/payment-setting')"
              >
              <b-popover
                triggers="hover"
                target="paymentSettings"
                placement="top"
              >Pengaturan Pembayaran</b-popover>
              <b-popover
                triggers="hover"
                target="detailSaldo"
                placement="top"
              >Detail Rincian Saldo</b-popover>
            </div>
          </div>
          <span class="text-4xl font-semibold">Rp {{ saldo.toLocaleString('id-ID') }}</span>
          <div class="d-flex mt-2">
            <img
              class="w-6"
              src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
              alt="kompoint"
            >
            <strong class="mx-[7px]">Kompoints</strong>
            <strong class="text-warning">: {{ profile.user_kompoints.toLocaleString('id-ID') }}</strong>
          </div>
        </b-card-body>
        <b-card-footer
          footer-bg-variant="primary"
          footer-text-variant="white"
          class="rounded-lg p-0 mt-1"
        >
          <b-row class="p-[10px]">
            <b-col
              class="d-flex justify-content-center cursor-pointer p-0"
              @click="handleTopup()"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/send-square.svg"
                alt="Top Up"
                class="my-auto mr-[6px]"
              >
              <span class="my-auto">Top Up</span>
            </b-col>
            <b-col
              class="d-flex justify-content-center cursor-pointer p-0"
              @click="getModalWithdrawal()"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/receive-square.svg"
                alt="Tarik Saldo"
                class="my-auto mr-[6px]"
              >
              <span class="my-auto">Tarik Saldo</span>
            </b-col>
            <b-col
              v-if="profile.role_id === 4"
              class="d-flex flex-col justify-center cursor-pointer p-0"
              @click="$router.push('/voucher')"
            >
              <div class="flex items-center justify-center gap-3">
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/card-send.svg"
                  alt="Detail"
                  class="my-auto mr-[6px]"
                >
                <span>Bayar</span>
              </div>
              <small class="text-center">
                Redeem Voucher
              </small>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-overlay>
    </b-card>
    <b-modal
      id="modalTopup"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-header
      hide-footer
      centered
      @shown="focusInput('topup')"
    >
      <p class="text-2xl text-center font-semibold my-1">
        Top Up Saldo
      </p>
      <b-form
        class="mb-1"
        @submit.prevent="!checkIsValid(nominalTopUp) || topUpBalance()"
      >
        <b-form-group>
          <label
            for="nominalTopUp"
            class="font-semibold"
          >Nominal</label>
          <b-form-input
            id="nominalTopUp"
            ref="inputTopupNominal"
            v-model="nominalTopUp"
            placeholder="minimal Rp10.000,-"
          />
          <small
            v-if="formatNumber(nominalTopUp) < 10000"
            class="text-danger"
          >*Minimal top up Rp 10.000</small>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <b-btn
              variant="outline-primary"
              block
              :disabled="loadingTopUp"
              @click="$bvModal.hide('modalTopup')"
            >
              Batal
            </b-btn>
          </b-col>
          <b-col cols="6">
            <b-btn
              type="submit"
              variant="primary"
              block
              :disabled="Number.isNaN(formatNumber(nominalTopUp))
                || formatNumber(nominalTopUp) < 10000
                || loadingTopUp"
            >
              <b-spinner
                v-if="loadingTopUp"
                class="mr-[6px]"
                small
              />
              Top Up Sekarang
            </b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="modalWithdrawal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-header
      hide-footer
      centered
      @shown="focusInput('withdrawal')"
    >
      <p class="text-2xl text-center font-semibold mt-1">
        Penarikan Saldo
      </p>
      <p class="text-center mb-1">
        Masukkan nominal penarikan dan bank transfer kamu, dan pastikan nomor rekening sesuai yang kamu tuju
      </p>
      <div class="p-1 mb-1 d-flex bg-[#FFF8E1] text-black rounded">
        <img
          class="place-self-start"
          src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
          alt="info"
        >
        <div class="ml-1">
          <div
            class="mb-1 text-lg"
            style="font-weight: 500;"
          >
            Gratis Biaya Transfer
          </div>
          <small>
            Penarikan <span style="font-weight: 500;">>Rp 500.000 GRATIS biaya transfer,</span> di bawahnya dikenakan biaya Rp 2.000
          </small>
        </div>
      </div>
      <b-form
        class="mb-1"
        @submit.prevent="() => withdrawalBalance(nominal)"
      >
        <b-form-group class="mb-0">
          <label
            for="nominalWithdrawal"
            class="font-semibold"
          >Nominal</label>
          <b-form-input
            id="nominalWithdrawal"
            ref="inputWithdrawalNominal"
            v-model="nominal"
            placeholder="minimal Rp10.000,-"
            @blur="checkWithdrawal()"
          />
          <small
            v-if="formatNumber(nominal) < 10000"
            class="text-danger"
          >*Minimal penarikan Rp 10.000</small>
          <small
            v-else-if="formatNumber(nominal) > saldo"
            class="text-danger"
          >*Saldo kamu tidak mencukupi</small>
        </b-form-group>
        <div
          v-if="formatNumber(nominal) > 10000 && formatNumber(nominal) <= saldo && withdrawalAllow"
          class="mb-1 text-sm"
        >
          <p class="mb-1 text-danger">
            Maksimal penarikan : <b>Rp {{ maxWithdrawal.toLocaleString('id-ID') }}</b>
          </p>
          <div class="bg-[#FFEDED] p-1 rounded-lg">
            <div class="d-flex mb-[8px]">
              <span class="k-info-circle-1 text-danger font-bold align-content-center mr-[10px] text-xl" />
              <div class="font-medium text-black text-[14px] align-content-center">
                Batas Minimal Saldo Ideal : <b>Rp {{ idealBalance.toLocaleString('id-ID') }}</b>
              </div>
            </div>
            <div class="mb-[8px]">
              Saldo ideal diterapkan untuk menghindari saldo minus. <a
                class="text-[#08A0F7] underline"
                href="https://bantuan.komerce.id/id/article/saldo-ideal-komship-7dn9vp/?bust=1724821138075"
                target="_blank"
              >Pelajari lebih lanjut</a>
            </div>
            <div class="p-1 bg-[#FFFFFF]">
              <div class="d-flex mb-[8px]">
                <span class="k-message-question font-bold align-content-center mr-[10px] text-lg" />
                <div class="font-medium align-content-center">
                  Darimana Saldo Ideal berasal ?
                </div>
              </div>
              <span>Potensi Pendapatan Order Dikirim <b>Rp {{ potencyIncome.toLocaleString('id-ID') }}</b> - Ongkir Retur Ketika Sampai <b>Rp {{ potencyRetur.toLocaleString('id-ID') }}</b> - Resiko Ongkir Menjadi Retur <b>Rp {{ riskRetur.toLocaleString('id-ID') }}</b> =
                Saldo Ideal <b class="text-danger">Rp {{ idealBalance.toLocaleString('id-ID') }}</b></span>
            </div>
          </div>
        </div>
        <b-form-group>
          <label
            for="nominalTopUp"
            class="font-semibold"
          >Rekening</label>
          <v-select
            v-model="selectedRekTujuan"
            label="text"
            :reduce="items => items.value"
            :options="rekTujuanOptions"
            @close="$refs.inputWithdrawalNominal.focus()"
          >
            <span
              v-if="rekTujuanOptions.length === 0"
              slot="no-options"
            >
              Anda belum menambahkan rekening bank
            </span>
          </v-select>
          <small>Pastikan rekening yang kamu pilih sudah benar</small>
        </b-form-group>
        <BRow v-if="profile.is_komcards !== 1">
          <BCol lg="12">
            <BAlert
              show
              variant="info"
              class="p-2"
            >
              <div class="flex flex-row items-center justify-between">
                <div>
                  <div class="flex items-center gap-3">
                    <div class="text-black font-medium text-[16px]">
                      Telah hadir
                    </div>
                    <img
                      src="https://storage.googleapis.com/komerce/assets/LP-Komcard/Logo-navbar.svg"
                      alt="Komerce"
                      width="100"
                    >
                  </div>
                  <div class="text-black font-normal text-[12px]">Ribet narik dana buat topup iklan? Pake Komcards!
                    <br>
                    Saldo Komship & bayar iklan saling terhubung
                  </div>
                </div>
                <a
                  href="https://komcards.id/integrasi-komship/#more"
                  target="_blank"
                >
                  <BButton
                    size="sm"
                    variant="outline-primary"
                  >
                    Cobain
                  </BButton>
                </a>
              </div>
            </BAlert>
          </BCol>
        </BRow>
        <b-row>
          <b-col cols="6">
            <b-btn
              variant="outline-primary"
              block
              @click="$bvModal.hide('modalWithdrawal')"
            >
              Batal
            </b-btn>
          </b-col>
          <b-col cols="6">
            <b-btn
              type="submit"
              variant="primary"
              block
              :disabled="selectedRekTujuan === null
                || Number.isNaN(formatNumber(nominal))
                || formatNumber(nominal) < 10000
                || formatNumber(nominal) > saldo
                || loadingWithdrawal
                || selectedRekTujuan === ''
                || withdrawalAllow"
            >
              <b-spinner
                v-if="loadingWithdrawal"
                class="mr-[6px]"
                small
              />
              Ajukan Penarikan
            </b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <PaymentMethods
      :is-weekend="isWeekend"
      :message-topup="messageTopup"
      @methodSelected="methodSelected"
    />
    <ScanQRIS
      :qr-bill-detail="qrBillDetail"
      @cancelPayment="cancelPayment"
      @paymentSuccess="paymentSuccess"
      @hidden="qrBillDetail = {}"
    />
    <ModalPaymentExist
      @continuePayment="continuePayment"
      @cancelPayment="cancelPayment"
    />
    <ModalPIN
      id="modal-verify-pin"
      :partner-id="profile.partner_id"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Verifikasi PIN"
      description="Mohon verifikasi identitas kamu dengan masukan PIN"
      label-confirm-button="Konfirmasi"
      label-cancel-button="Kembali"
      @on-confirm-button="successVerifyPIN"
      @on-cancel-button="backToWithdrawalModal"
    />

    <ModalMaxChancePIN />
  </b-col>
</template>
<script>
import vSelect from 'vue-select'
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import '@core/scss/vue/libs/vue-select.scss'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalPIN from '@/views/components/modal/ModalPIN.vue'
import ModalMaxChancePIN from '@/views/components/modal/ModalMaxChancePIN.vue'
import PaymentMethods from './modal/PaymentMethod.vue'
import ScanQRIS from './modal/ScanQRIS.vue'
import ModalPaymentExist from './modal/ModalPaymentExist.vue'

export default {
  components: {
    vSelect,
    PaymentMethods,
    ScanQRIS,
    ModalPaymentExist,
    ModalPIN,
    ModalMaxChancePIN,
  },
  data() {
    return {
      loadingTopUp: false,
      loadingWithdrawal: false,
      idealBalance: 0,
      potencyIncome: 0,
      potencyRetur: 0,
      maxWithdrawal: 0,
      riskRetur: 0,
      withdrawalAllow: false,
      dataBillExist: null,
      qrBillDetail: {},

      isWeekend: false,
      messageTopup: '',
    }
  },
  computed: {
    ...mapState('dashboard', ['saldo', 'loadingCurrentSaldo', 'profile']),
    ...mapGetters('saldo', ['rekenings', 'rekening', 'rekTujuanOptions']),
    ...mapFields('saldo', [
      'nominalTopUp',
      'selectedRekTujuan',
      'nominal',
      'pin',
    ]),
  },
  watch: {
    nominal(newValue) {
      this.withdrawalAllow = false
      const result = newValue.replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      this.$nextTick(() => {
        this.nominal = `Rp ${result}`
      })
    },
    nominalTopUp(newValue) {
      const result = newValue.replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      this.$nextTick(() => {
        this.nominalTopUp = `Rp ${result}`
      })
    },
  },
  mounted() {
    if (this.$route.params.topup === true) {
      this.handleTopup()
    }
  },
  methods: {
    formatNumber(value) {
      // eslint-disable-next-line radix
      const result = parseInt(value.replace(/[^0-9]/g, ''))
      return result
    },
    async topUpBalance() {
      if (this.formatNumber(this.nominalTopUp) > 500000) {
        this.loadingTopUp = true
        try {
          const response = await this.$store.dispatch('saldo/newTopUpSaldo')
          const { data } = response
          if (!data.status) throw data
          try {
            window.open(data.data.invoice_url, '_blank').focus()
          } catch (e) {
            this.$bvModal.hide('modalTopup')
            this.loadingTopUp = false
            this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
          }
          this.$bvModal.hide('modalTopup')
          this.loadingTopUp = false
          this.$swal({
            html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
            confirmButtonText: 'Cek Rincian Saldo',
            confirmButtonClass: 'btn btn-primary',
          }).then(then => {
            if (then.isConfirmed) {
              this.$router.push('/keuangan/saldo/detail')
            }
          })
        } catch (e) {
          this.$bvModal.hide('modalTopup')
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        } finally {
          this.$store.commit('saldo/UPDATE_NOMINAL', '')
        }
      } else {
        this.getWeekend()
        this.$bvModal.show('paymentMethod')
      }
    },
    getWeekend() {
      const day = moment().day() // 0 = Minggu, 1 = Senin, ..., 6 = Sabtu
      const today = moment()
      const month = today.month() + 1 // Bulan sekarang (1-12)
      const holidays = {
        1: ['1', '27', '29'],
        3: ['31'],
        4: ['1', '2', '7'],
        5: ['12'],
        6: ['9'],
      }

      // ** Kondisi 2: Validasi Tanggal Merah **
      const currentHolidays = holidays[month] ? holidays[month].map(date => moment(`${month}-${date}`, 'M-D')) : []
      const holidayMatch = currentHolidays?.find(holiday => holiday.isSame(today, 'day'))

      this.isWeekend = (day >= 4 && day <= 6) || holidayMatch

      if (day >= 4 && day <= 6) { // Kamis (4), Jumat (5), Sabtu (6)
        const startOfWeek = today.clone().startOf('isoWeek').add(3, 'days')
        const endOfWeek = today.clone().startOf('isoWeek').add(5, 'days')
        this.messageTopup = `${startOfWeek.format('D')} - ${endOfWeek.format('D MMMM YYYY')}`
      } else if (holidayMatch) {
        const twoDaysBefore = holidayMatch.clone().subtract(2, 'days')
        this.messageTopup = `${twoDaysBefore.format('D')} - ${holidayMatch.format('D MMMM YYYY')}`
      } else {
        this.messageTopup = 'tidak ada biaya admin'
      }
    },
    async checkWithdrawal() {
      if (this.nominal !== '' && this.selectedRekTujuan !== '') {
        try {
          const response = await this.$http_new.get(`/komship/api/v1/admin/partner-consumables/balance-analitycs/${this.profile.partner_id}`)
          const { data } = response.data
          this.idealBalance = data.ideal_balance
          this.potencyIncome = data.income_order_potential
          this.potencyRetur = data.ongkir_retur_on_finished
          this.riskRetur = data.ongkir_risk_become_retur
          this.maxWithdrawal = this.saldo - data.ideal_balance
          this.withdrawalAllow = this.maxWithdrawal < this.formatNumber(this.nominal)
        } catch (error) {
          console.log(error)
        }
      }
    },
    getModalTopUp() {
      this.nominalTopUp = ''
      this.$bvModal.show('modalTopup')
    },
    getModalWithdrawal() {
      if (this.profile.role_name === 'Talent Global') {
        this.$swal({
          text: 'Maaf akun kamu tidak memiliki akses untuk penarikan saldo.',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          allowOutsideClick: true,
          confirmButtonText: 'Kembali',
          confirmButtonClass: 'btn btn-primary',
        })
      } else if (this.rekTujuanOptions.length < 1) {
        this.$swal({
          text: 'Maaf kamu belum menambahkan rekening untuk penarikan saldo.',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          allowOutsideClick: false,
          confirmButtonText: 'Tambah Rekening',
          confirmButtonClass: 'btn btn-primary',
        }).then(response => {
          if (response.isConfirmed) {
            this.$router.push({ name: 'kompship-rekening-bank' })
          }
        })
      } else {
        this.loadingWithdrawal = false
        this.idealBalance = 0
        this.potencyIncome = 0
        this.potencyRetur = 0
        this.withdrawalAllow = false
        this.nominal = ''
        this.selectedRekTujuan = ''
        this.$bvModal.show('modalWithdrawal')
      }
    },
    async withdrawalBalance(nominal) {
      const disabledWithdrawal = this.formatNumber(nominal) < 10000 || this.formatNumber(nominal) > this.saldo
      if (!disabledWithdrawal) {
        this.loadingWithdrawal = true
        try {
          await this.checkWithdrawal()
          if (!this.withdrawalAllow) {
            this.$bvModal.hide('modalWithdrawal')
            this.checkVerifyPIN()
          }
          this.loadingWithdrawal = false
        } catch (error) {
          this.loadingWithdrawal = false
          console.error(error)
        }
      }
    },
    checkVerifyPIN() {
      this.$http_new.get('/auth/api/v1/user/secured/verify-pin/attempt-left')
        .then(response => {
          const { data } = response.data
          if (data.attempt_left === 0) {
            this.$bvModal.show('max-chance-PIN')
          } else {
            this.$bvModal.show('modal-verify-pin')
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal cek PIN, silahkan refresh page' })
        })
    },
    successVerifyPIN(value) {
      this.withdrawalRequest()
    },
    backToWithdrawalModal() {
      this.$bvModal.hide('modal-verify-pin')
      this.$bvModal.show('modalWithdrawal')
    },
    async withdrawalRequest() {
      try {
        const withdrawalRequest = await this.$store.dispatch(
          'saldo/withdrawalRequest',
        )
        const { code } = withdrawalRequest.data
        const selectedAccount = this.rekenings.find(items => items.id === this.selectedRekTujuan)
        if (code === 200) {
          this.$swal({
            title: '<span class="font-semibold h4">Penarikan Saldo Berhasil</span>',
            text: `Penarikan sebesar ${this.nominal} akan segera dikirim ke rekening atas nama ${selectedAccount.nama} - ${selectedAccount.bank} dalam 1x24 jam`,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/success.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      } catch (error) {
        let message = ''
        if (error.response.status === 400) message = 'Maaf, kamu tidak bisa melakukan penarikan saldo dikarenakan kamu masih memiliki antrian penarikan yang belum disetujui.'
        else message = 'Penarikan tidak dapat dilakukan karena terjadi kesalahan, tunggu beberapa saat dan mulai penarikan kembali'
        this.$swal({
          title: '<span class="font-semibold h4">Penarikan Saldo Gagal</span>',
          text: message,
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
      }
    },
    async methodSelected(method) {
      if (method === 'qris' && !this.isWeekend) {
        try {
          const response = await this.$store.dispatch('saldo/qrisTopup')
          const { data } = response

          if (!data.status) throw data

          if (data.data) {
            const {
              amount,
              expires_at,
              qr_string,
              id,
            } = data.data

            this.qrBillDetail = {
              amount,
              expire: expires_at,
              qrString: qr_string,
              qrId: id,
            }

            this.$bvModal.hide('paymentMethod')
            this.$bvModal.show('scanQris')
          }
        } catch (e) {
          this.$bvModal.hide('modalTopup')
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      }
      if (method === 'bank') {
        this.$bvModal.hide('paymentMethod')

        const result = this.formatNumber(this.nominalTopUp)
        this.nominalTopUp = `Rp ${result}`
        this.loadingTopUp = true
        try {
          const response = await this.$store.dispatch('saldo/newTopUpSaldo', true)
          const { data } = response
          if (!data.status) throw data
          try {
            this.$bvModal.hide('modalTopup')
            window.open(data.data.invoice_url, '_blank').focus()
          } catch (e) {
            this.$bvModal.hide('modalTopup')
            this.loadingTopUp = false
            this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
          }
          this.$bvModal.hide('modalTopup')
          this.$bvModal.hide('paymentMethod')
          this.loadingTopUp = false
          this.$swal({
            html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
            confirmButtonText: 'Cek Rincian Saldo',
            confirmButtonClass: 'btn btn-primary',
          }).then(then => {
            if (then.isConfirmed) {
              this.$router.push('/keuangan/saldo/detail')
            }
          })
        } catch (e) {
          this.$bvModal.hide('modalTopup')
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        } finally {
          this.$store.commit('saldo/UPDATE_NOMINAL', '')
        }
      }
    },
    async handleTopup() {
      try {
        const response = await this.$store.dispatch('saldo/checkActiveBill')
        const { data } = response
        if (data.data.have_active_bill) {
          this.dataBillExist = data.data
          this.$bvModal.show('modalPaymentExist')
        } else {
          this.getModalTopUp()
        }
        if (!data.status) throw data
      } catch (e) {
        this.$swal({
          title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
          text: e.message,
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
      }
    },
    async continuePayment() {
      if (!this.dataBillExist.invoice_xendit_url) {
        const {
          qr_amount,
          qr_expire_date,
          qr_xendit_qrstring,
          qr_xendit_id,
        } = this.dataBillExist

        this.qrBillDetail = {
          amount: qr_amount,
          expire: qr_expire_date,
          qrString: qr_xendit_qrstring,
          qrId: qr_xendit_id,
        }

        this.$bvModal.show('scanQris')
      } else {
        this.$bvModal.hide('modalPaymentExist')
        try {
          window.open(this.dataBillExist.invoice_xendit_url, '_blank').focus()
        } catch (e) {
          this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
        }
        this.$bvModal.hide('modalTopup')
        this.loadingTopUp = false
        this.$swal({
          html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          confirmButtonText: 'Cek Rincian Saldo',
          confirmButtonClass: 'btn btn-primary',
        }).then(then => {
          if (then.isConfirmed) {
            this.$router.push('/keuangan/saldo/detail')
          }
        })
      }
    },
    async cancelPayment(qr) {
      try {
        const response = await !qr && this.dataBillExist.invoice_xendit_id ? this.$store.dispatch('saldo/cancelActiveBill', this.dataBillExist.invoice_xendit_id) : this.$store.dispatch('saldo/cancelQrBill', qr || this.dataBillExist.qr_xendit_id)
        const { data } = await response
        if (data.code === 200) {
          this.toastSuccess('Berhasil membatalkan pembayaran')

          this.$bvModal.hide('modalPaymentExist')
          this.$bvModal.hide('scanQris')
          this.$bvModal.hide('modalTopup')
          this.loadingTopUp = false
        }
        if (!data.status) throw data
      } catch ({ response }) {
        if (response.status === 400 || (response.status === 404 && response.data.data.detail === 'could not find invoice by id')) {
          this.toastSuccess('Pembayaran sudah berhasil dilakukan')
          this.$bvModal.hide('modalPaymentExist')
          this.$store.dispatch('dashboard/getBalanceSummary')
        } else {
          this.$swal({
            title: '<span class="font-semibold h4">Pembatalan Pembayaran Gagal</span>',
            text: response.data.data,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      }
    },
    paymentSuccess(message) {
      this.toastSuccess(message)

      this.$store.dispatch('dashboard/getBalanceSummary')
      this.$bvModal.hide('scanQris')
      this.$bvModal.hide('modalPaymentExist')
      this.loadingTopUp = false
      this.$bvModal.hide('modalTopup')
    },
    toastFailed(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed',
          icon: 'AlertCircleIcon',
          text,
          variant: 'danger',
        },
      }, 2000)
    },
    toastSuccess(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'SuccessIcon',
          text,
          variant: 'success',
        },
      }, 1000)
    },
    checkIsValid(nominalTopUp) {
      if (Number.isNaN(this.formatNumber(nominalTopUp)) || this.formatNumber(nominalTopUp) < 10000 || this.loadingTopUp) {
        return false
      // eslint-disable-next-line no-else-return
      } else {
        return true
      }
    },
    focusInput(state) {
      switch (state) {
        case ('topup'):
          this.$refs.inputTopupNominal.focus()
          break
        case ('withdrawal'):
          this.$refs.inputWithdrawalNominal.focus()
          break
        default:
          break
      }
    },
  },
}
</script>
