var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{ref:"change-password-team",attrs:{"id":"change-password-team","no-stacking":"","centered":"","hide-footer":"","hide-header":"","hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":"","size":"md","body-class":"p-2"}},[_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"text-center text-black text-xl font-semibold"},[_vm._v(" Ubah Password ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-col gap-4"},[_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('strong',{staticClass:"text-md font-medium"},[_vm._v("Password Baru")])])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:8|regex:/^(?=.*[A-Z])(?=.*[a-z])(?=.*\\\\d)(?=.*[!@#$%^&*]).+$/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BInputGroup',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('BFormInput',{attrs:{"autofocus":"","type":_vm.type,"placeholder":"Masukkan password","state":errors.length > 0 ? false : null},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}}),_c('BInputGroupAppend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.icon},on:{"click":function($event){return _vm.togglePasswordVisibility('new')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('BFormGroup',{staticClass:"m-0",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('strong',{staticClass:"text-md font-medium"},[_vm._v("Konfirmasi Password")])])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Konfirmasi Password","rules":{
              required: true,
              confirmed: _vm.new_password
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BInputGroup',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('BFormInput',{attrs:{"placeholder":"Masukkan ulang password","type":_vm.typeConfirm,"state":errors.length > 0 ? false : null},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c('BInputGroupAppend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.icon},on:{"click":function($event){return _vm.togglePasswordVisibility('confirm')}}})],1)],1),_c('small',{staticClass:"text-danger"},[(errors.length)?[_vm._v(_vm._s(errors[0]))]:_vm._e()],2)]}}],null,true)})],1),_c('BRow',{staticClass:"justify-center"},[_c('BCol',{attrs:{"cols":"6"}},[_c('BButton',{attrs:{"variant":"outline-primary","block":"","size":"md","type":"reset"},on:{"click":_vm.onClose}},[_vm._v(" Batal ")])],1),_c('BCol',{attrs:{"cols":"6"}},[_c('BButton',{attrs:{"block":"","variant":invalid || _vm.loading ? 'secondary' : 'primary',"type":"submit","disabled":invalid || _vm.loading,"size":"md"},on:{"click":_vm.onFinish}},[(_vm.loading)?_c('BSpinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan ")],1)],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }