<template>
  <div class="min-h-screen grid sm:grid-cols-2 bg-white text-center">
    <div class="flex flex-col">
      <div
        class="my-2 sm:ml-10"
      >
        <b-img
          class="mx-[auto] sm:mx-0"
          src="https://storage.googleapis.com/komerce/assets/logo/logo-komerce-no-tag.svg"
          alt="logo"
          width="176px"
        />
      </div>
      <div class="text-black flex items-center justify-center flex-col flex-grow px-2">
        <h2 class="text-[34px] text-black leading-[51px] font-semibold">
          Verifikasi Berhasil! 🎉
        </h2>
        <p class="mb-[72px] text-sm text-[#828282]">
          Selamat! Akun kamu berhasil diverifikasi.
        </p>
        <b-img-lazy
          src="https://storage.googleapis.com/komerce/core/iconsquare-popup-success.png"
          alt="logo berhasil"
          width="112px"
          height="112px"
          class="mb-[49px]"
        />
        <!-- <h3 class="text-black text-xl font-bold mb-[16px]">
          Selamat! Akun kamu telah ditambahkan.
        </h3> -->
        <p class="max-w-md text-sm text-center mb-[30px]">
          Sekarang kamu dapat memulai menggunakan layanan Komerce
        </p>
        <b-button
          block
          variant="primary"
          tag="router-link"
          :to="{name:'auth-login'}"
          class="max-w-md"
        >
          Masuk
        </b-button>
      </div>
    </div>
    <div class="mt-[4rem] sm:mt-0">
      <b-img-lazy
        class="sm:h-screen float-right"
        src="https://storage.googleapis.com/komerce/core/bg-verified.svg"
        alt="logo berhasil"
      />
    </div>
  </div>
</template>

<script>
import { BButton, BImgLazy, BImg } from 'bootstrap-vue'
/* eslint-disable global-require */
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BButton,
    BImgLazy,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>
