var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{ref:"modal-edit-viewer",attrs:{"id":"modal-edit-viewer","no-stacking":"","centered":"","hide-footer":"","hide-header":"","hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":"","size":"md","body-class":"p-2"}},[_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"text-center text-black text-xl font-semibold"},[_vm._v(" Edit Penonton ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-col gap-1"},[_c('div',{staticClass:"flex item-center justify-between gap-4"},[_c('BFormGroup',{staticClass:"w-full",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-md text-black"},[_vm._v(" Minimal Umur ")])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Minimal Umur","rules":{ required: true, minAgeViewer: 21, maxAgeViewer: 65 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{attrs:{"autofocus":"","placeholder":"Minimal umur","state":errors.length > 0 ? false : null},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.updateMaxAgeRule},model:{value:(_vm.age.min),callback:function ($$v) {_vm.$set(_vm.age, "min", $$v)},expression:"age.min"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('BFormGroup',{staticClass:"w-full",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-md text-black"},[_vm._v(" Maksimal Umur ")])]},proxy:true}],null,true)},[_c('ValidationProvider',{ref:"maxAgeProvider",attrs:{"name":"Maksimal Umur","rules":{ required: true, maxAgeRule: _vm.maxAgeRule }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('BFormInput',{attrs:{"placeholder":"Maksimal umur","state":errors.length > 0 ? false : null},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.age.max),callback:function ($$v) {_vm.$set(_vm.age, "max", $$v)},expression:"age.max"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('BFormGroup',{staticClass:"w-full",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-md text-black"},[_vm._v(" Jenis Kelamin ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"flex items-center gap-1"},_vm._l((_vm.listGender),function(item){return _c('BButton',{key:item.value,staticClass:"mr-[5px] flex",attrs:{"variant":_vm.gender === item.value ? 'primary' : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.selectedGender(item)}}},[_c('span',{staticClass:"font-medium",class:_vm.gender === item.value ? 'text-white' : 'text-black'},[_vm._v(_vm._s(item.label))])])}),1)]),_c('BFormGroup',{staticClass:"w-full",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-md text-black"},[_vm._v(" Kategori Bisnis ")])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Kategori Bisnis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VSelect',{attrs:{"options":_vm.optionsCategory,"reduce":function (option) { return option.value; },"loading":_vm.isLoadingCategory,"disabled":_vm.isLoadingCategory},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},[_c('span',{attrs:{"slot":"no-options"},on:{"click":function($event){_vm.$refs.select.open = false}},slot:"no-options"})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('BFormGroup',{staticClass:"w-full",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-md text-black"},[_vm._v(" Lokasi ")])]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Lokasi","rules":{ required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VSelect',{attrs:{"options":_vm.optionsLocations,"reduce":function (option) { return option.value; },"label":"name","loading":_vm.isLoadingLocation,"disabled":_vm.isLoadingLocation},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}},[_c('span',{attrs:{"slot":"no-options"},on:{"click":function($event){_vm.$refs.select.open = false}},slot:"no-options"})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('BRow',{staticClass:"justify-center"},[_c('BCol',{attrs:{"cols":"6"}},[_c('BButton',{attrs:{"variant":"outline-primary","block":"","size":"md","type":"reset"},on:{"click":function($event){return _vm.$bvModal.hide('modal-edit-viewer')}}},[_vm._v(" Batal ")])],1),_c('BCol',{attrs:{"cols":"6"}},[_c('BButton',{attrs:{"block":"","variant":invalid || _vm.loading ? 'secondary' : 'primary',"type":"submit","disabled":invalid || _vm.loading,"size":"md"},on:{"click":_vm.onFinish}},[(_vm.loading)?_c('BSpinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan ")],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }