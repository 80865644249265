<template>
  <b-modal
    id="modal-validation-access-broadcast"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="p-1">
      <div class="d-block text-center">
        <b-img
          center
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        />
        <div class="text-2xl  mt-1 font-bold text-[#333333]">
          Kamu Belum Dapat Melakukan Broadcast
        </div>
        <div class="text-[16px] mt-1 text-[400]">
          Kamu Belum berlangganan Komchat, Silahkan Berlangganan <b class="text-black">Komchat Basic</b> untuk Broadcast.
        </div>
      </div>
      <b-row class="flex justify-center mt-3 items-center">
        <b-col class="flex justify-center">
          <b-button
            variant="primary"
            @click="handleRedirect()"
          >
            <span>Berlangganan Sekarang </span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    isExpired: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleRedirect() {
      if (this.isExpired) {
        this.$router.push('/aplikasiku')
      } else {
        this.$router.push('/opsional-feature')
      }
    },
  },
}
</script>
