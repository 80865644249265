<template>
  <BModal
    id="change-password-team"
    ref="change-password-team"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    body-class="p-2"
  >
    <div class="flex flex-col gap-4">
      <div class="text-center text-black text-xl font-semibold">
        Ubah Password
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <div class="flex flex-col gap-4">
          <BFormGroup>
            <template #label>
              <div class="text-black flex items-center">
                <strong class="text-md font-medium">Password Baru</strong>
              </div>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Password"
              rules="required|min:8|regex:/^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[!@#$%^&*]).+$/"
            >
              <BInputGroup
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <BFormInput
                  v-model="new_password"
                  autofocus
                  :type="type"
                  placeholder="Masukkan password"
                  :state="errors.length > 0 ? false : null"
                />
                <BInputGroupAppend is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="icon"
                    @click="togglePasswordVisibility('new')"
                  />
                </BInputGroupAppend>
              </BInputGroup>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>

          <BFormGroup class="m-0">
            <template #label>
              <div class="text-black flex items-center">
                <strong class="text-md font-medium">Konfirmasi Password</strong>
              </div>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Konfirmasi Password"
              :rules="{
                required: true,
                confirmed: new_password
              }"
            >
              <BInputGroup
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <BFormInput
                  v-model="confirm_password"
                  placeholder="Masukkan ulang password"
                  :type="typeConfirm"
                  :state="errors.length > 0 ? false : null"
                />
                <BInputGroupAppend is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="icon"
                    @click="togglePasswordVisibility('confirm')"
                  />
                </BInputGroupAppend>
              </BInputGroup>
              <small class="text-danger">
                <template v-if="errors.length">{{ errors[0] }}</template>
              </small>
            </ValidationProvider>
          </BFormGroup>
          <BRow class="justify-center">
            <BCol cols="6">
              <BButton
                variant="outline-primary"
                block
                size="md"
                type="reset"
                @click="onClose"
              >
                Batal
              </BButton>
            </BCol>
            <BCol cols="6">
              <BButton
                block
                :variant="invalid || loading ? 'secondary' : 'primary'"
                type="submit"
                :disabled="invalid || loading"
                size="md"
                @click="onFinish"
              >
                <BSpinner
                  v-if="loading"
                  small
                />
                Simpan
              </BButton>
            </BCol>
          </BRow>
        </div>
      </ValidationObserver>
    </div>
  </BModal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import { required } from '@validations'
import { newAxiosIns } from '@/libs/axios'
import { toast_error, toast_success } from '@/libs/toastification'

extend('min', {
  validate: (value, { length }) => value.length >= length,
  message: 'Minimal {length} karakter',
})

extend('regex', {
  validate: value => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]).+$/.test(value),
  message:
    'Password harus mengandung minimal 1 huruf besar, 1 huruf kecil, 1 angka, dan 1 simbol',
})

extend('confirmed', {
  validate: (value, { target }) => value === target,
  params: ['target'],
  message: 'Konfirmasi password harus sama dengan password.',
})

export default {
  components: { ValidationObserver, ValidationProvider },
  props: {
    teamId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      new_password: '',
      confirm_password: '',
      type: 'password',
      typeConfirm: 'password',
      showPassword: false,
      showConfirmPassword: false,
      icon: 'EyeIcon',
      iconConfirm: 'EyeIcon',
      loading: false,
    }
  },
  methods: {
    async onFinish() {
      this.loading = true
      const url = `/auth/api/v1/teams/${this.teamId}/reset-password`
      await newAxiosIns.put(url, {
        password: this.new_password,
      })
        .then(() => {
          this.loading = false
          toast_success({
            title: 'Berhasil',
            message: 'Informasi reset password telah dikirim ke email kamu dan team',
          })
          this.onClose()
        })
        .catch(() => {
          this.loading = false
          toast_error({
            title: 'Gagal',
            message: 'Password gagal diubah',
          })
        })
    },
    onClose() {
      this.$bvModal.hide('change-password-team')
      this.new_password = ''
      this.confirm_password = ''
    },
    togglePasswordVisibility(value) {
      switch (value) {
        case 'new':
          this.showPassword = !this.showPassword
          if (!this.showPassword) {
            this.icon = 'EyeIcon'
            this.type = 'password'
          } else {
            this.icon = 'EyeOffIcon'
            this.type = 'text'
          }
          break
        case 'confirm':
          this.showConfirmPassword = !this.showConfirmPassword
          if (!this.showConfirmPassword) {
            this.iconConfirm = 'EyeIcon'
            this.typeConfirm = 'password'
          } else {
            this.iconConfirm = 'EyeOffIcon'
            this.typeConfirm = 'text'
          }
          break
        default:
          break
      }
    },
  },
}
</script>
